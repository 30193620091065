import React, {Component, Fragment} from 'react';
import '../../styles/css/card_details.css';
import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";
import CardDetailsProperty from "./CardDetailsProperty";
import {clearHtml} from "../../utils/clear-html";


// todo рендерится не лениво (getContent не помогает) и в три подхода, это не норма?
class CardDetails extends Component {
    static propTypes = {
        nodeBody: PropTypes.any.isRequired,
        taskNodeType: PropTypes.any.isRequired,
        usersById: PropTypes.objectOf(Object).isRequired
    };

    render() {
        const {t, nodeBody, taskNodeType, usersById} = this.props;
        const {properties, meta} = nodeBody;

        const typeProps = taskNodeType.properties
            .map(p => ({key: p.name, typeId: p.type_id}))
            .map(({key, typeId}) => ({key, typeId, value: properties.byType[key]}))
            .filter(p => p.value);

        const userProps = properties.byUser.map(({key, value, type_id: typeId}) => ({key, value, typeId}));

        const author = usersById[meta.author];
        const latestEditor = usersById[meta.last_modified_user];

        // todo Компонент для отображения пользователя по id
        return (
            <div className='card-details-container text-overflow'>
                <div
                    className={'card-title'}
                    dangerouslySetInnerHTML={{ __html: clearHtml(properties.global.title) }}
                />

                {author
                    ? <Fragment>
                        <div><b>{t`card.author`}:</b> {author.username}</div>
                        <div><b>{t`card.creation_date_label`}:</b> {t("card.creation_date", { date: new Date(meta.creation_timestamp) })}
                        </div>
                    </Fragment>
                    : null
                }

                {latestEditor
                    ? <Fragment>
                        <div><b>{t`card.last_modified`}:</b> {latestEditor.username}</div>
                        <div><b>{t`card.last_modified_date_label`}:</b> {t("card.last_modified_date", { date: new Date(meta.last_modified_timestamp) })}
                        </div>
                    </Fragment>
                    : null
                }

                {typeProps.length
                    ? <div>
                        <h3>{t`card.properties.by_type`}</h3>
                        {typeProps.map(({key, value, typeId}) =>
                            <CardDetailsProperty key={key} name={key} value={value} typeId={typeId}/>)
                        }
                    </div>
                    : null
                }

                {userProps.length
                    ? <div>
                        <h3>{t`card.properties.by_user`}</h3>
                        {userProps.map(({key, value, typeId}) =>
                            <CardDetailsProperty key={key} name={key} value={value} typeId={typeId}/>)
                        }
                    </div>
                    : null
                }
            </div>
        )
    }
}

export default withNamespaces()(CardDetails);
