import { connect } from 'react-redux';
import Home from './Home';
import {changeView} from '../../actions/ViewActions';

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.boardReducer.isFetching
  };
}

function mapStateToDispatch(dispatch) {
  return {
    onChangeView: (viewName) => {
        dispatch(changeView(viewName));
    }
  };
}

export const HomeContainer = connect(mapStateToProps, mapStateToDispatch)(Home);
