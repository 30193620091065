import Lh from '../helpers/LaneHelper';
import update from 'immutability-helper';
import _ from 'lodash';

import * as actions from '../actions/KanbanAction';
import settings from '../settings';
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import questionMarkIcon from 'images/questionmark.png';

const boardReducer = (state = {
  isFetching: false,
  node: {},
  types: [],
  isFailed: false,
  errorText: '',
  rootInfo: {
    name: '',
    start: '',
    end: '',
    isSprint: false,
    isFailed: false,
    errorText: ''
  },
  nodeTypes: {
    isFetching: false,
    sprintNodeTypeId: null,
    // categoryNodeTypeId: null,
    taskNodeType: null
  },
  taskStatuses: {
    order: [],
    visibility: {},
    width: {},
    icons: {}
  },
  areAllLanesVisible: true,
  executors: {
    areAllVisible: true,
    areWithoutExecutorVisible: true,
    byEmail: {}
  },
  search: {
    active: false,
    cardsId: []
  },
  users: {
    isFetching: false,
    isFailed: false,
    errorText: '',
    byId: {}
  },
  lanes: []
}, action) => {
  const {payload, type} = action;
  switch (type) {
    case actions.REQUEST_NODES:
      return update(state, {$merge: {
        isFetching: true,
        isFailed: false,
      }});

    case actions.RECEIVE_NODES:
      const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };

      const rootNode = action.payload.node;
      const nodeProperties = rootNode.body.properties;
      const sprintNodePropertyNames = settings.KANBAN.SPRINT_NODE_TYPE.property_names;

      const name = nodeProperties.global.title || 'Unnamed';
      const isSprint  = rootNode.body.type_id === state.nodeTypes.sprintNodeTypeId;
      const startDate = isSprint && nodeProperties.byType[sprintNodePropertyNames.start] ?
          new Date(nodeProperties.byType[sprintNodePropertyNames.start]) : null;
      const endDate =  isSprint && nodeProperties.byType[sprintNodePropertyNames.end] ?
          new Date(nodeProperties.byType[sprintNodePropertyNames.end]) : null;
      const lanes = Lh.createLanes(rootNode, state);
      const executorEmails = {};

      lanes.forEach(lane => {lane.cards.forEach(card => {
        if (card.executorEmail && !(card.executorEmail in executorEmails)) {
          const executor = Object.values(state.users.byId).find(user => user.username === card.executorEmail);
          if (executor)
            executorEmails[card.executorEmail] = {
              avatar: executor.avatar,
              isVisible: true
            };
        }
      })});

      return update(state, {
        $merge: {
          isFetching: false,
          isFailed: false,
          lanes: lanes
        },
        executors: {byEmail: {$set: executorEmails}},
        rootInfo: {$merge: {
          name: name,
          isSprint: isSprint,
          start: startDate,
          end: endDate
        }}
      });

    case actions.RECEIVE_NODES_FAIL:
      return update(state, {$merge: {
        isFetching: false,
        isFailed: true,
        errorText: action.payload
      }});

    case actions.REQUEST_NODE_TYPES:
      return update(state, {nodeTypes: {$merge: {
        isFetching: true
      }}});

    case actions.RECEIVE_NODE_TYPES:
      const nodeTypes = action.payload;

      let typeStatusesOrder = [];
      let typeStatusesIcons = {"" : questionMarkIcon};
      _.get(
        _.find(
          _.get(
            _.find(
              nodeTypes,
              ['name', settings.KANBAN.TASK_NODE_TYPE.name]
            ),
            'properties', []
          ),
          ['name', settings.KANBAN.TASK_NODE_TYPE.property_names.status]
        ),
        'icons', []
      ).forEach(icon => {
        const name = _.get(icon, 'text', null);
        typeStatusesOrder.push(name);
        typeStatusesIcons[name] = _.get(icon, 'img', null);
      });
      typeStatusesOrder.push("");

      const savedStatuses = LocalStorageHelper.getLanes();
      let order = [];
      let visibility = {};
      let width = {};

      if (savedStatuses) {
        const savedOrder = savedStatuses.order;
        const savedVisibility = savedStatuses.visibility || {};
        const savedWidth = savedStatuses.width || {};

        let statuses = [];

        savedOrder.forEach(status => {
          if (typeStatusesOrder.includes(status))
            statuses.push(status);

          else {
            if (savedVisibility[status] !== undefined)
              delete savedVisibility[status];
            if (savedWidth[status] !== undefined)
              delete savedWidth[status];
          }
        });

        typeStatusesOrder.forEach(status => {
          if (!statuses.includes(status))
            statuses.push(status);
        });

        order = statuses;
        visibility = savedVisibility;
        width = savedWidth;
      } else {
        order = typeStatusesOrder;
        visibility = Object.fromEntries(order.map(text => [text, true]));
        width = Object.fromEntries(order.map(text => [text, 350]));
      }

      // TODO: Кидать эксепшн, если нет одного из нижеследующих типов

      return update(state, {nodeTypes: {$merge: {
        isFetching: false,
        sprintNodeTypeId: _.get(_.find(nodeTypes, ['name', settings.KANBAN.SPRINT_NODE_TYPE.name]), 'id', null),
        // categoryNodeTypeId: _.get(_.find(nodeTypes, ['name', settings.KANBAN.CATEGORY_NODE_TYPE.name]), 'id', null),
        taskNodeType: _.find(nodeTypes, ['name', settings.KANBAN.TASK_NODE_TYPE.name]),
      }},
        taskStatuses: {$set: {
            order: order,
            visibility: visibility,
            width: width,
            icons: typeStatusesIcons,
          }},
      });

    case actions.NOT_PASSED_MAP_INFO:
      return update(state, {rootInfo: {$merge: {
        isFailed: true,
        errorText: payload.message
      }}});

    case actions.REQUEST_MAP_USERS:
      return update(state, {
        executors: {$merge: {isFetching: true, isFailed: false}},
        users: {$merge: {isFetching: true, isFailed: false}}
      });

    case actions.RECEIVE_MAP_USERS:
      const usersById = {};
      for (const user of payload) {
        user.id = user.user_id; // исправим бредовое API RF..
        delete user.user_id;
        delete user.map_id;
        usersById[user.id] = user
      }

      return update(state, {
        users: {
          $merge: {
            isFetching: false,
            isFailed: false,
            errorText: '',
            byId: usersById
          }
        }});

    case actions.RECEIVE_MAP_USERS_FAIL:
      return update(state, {
        users: { $merge: { isFetching: false, isFailed: true, errorText: payload } }, // fixme обработка ошибок так не работает
      });

    case actions.TOGGLE_EXECUTOR_VISIBLE:
      return update(state, {executors: {
        byEmail: {[payload]: {$toggle: ['isVisible']}}
      }});

    case actions.TOGGLE_WITHOUT_EXECUTOR_VISIBLE:
      return update(state, {executors: {$toggle: ['areWithoutExecutorVisible']}});

    case actions.TOGGLE_EXECUTOR_VISIBLE_ALL:
      return update(state, {executors: {$toggle: ['areAllVisible'],
        byEmail: {$apply: function (byEmail) {
          let newByEmail = {};
          Object.keys(byEmail).forEach(username => {
            newByEmail[username] = update(byEmail[username], {isVisible: {$set: !state.executors.areAllVisible}});
          });
          return update(byEmail, {$set: newByEmail});
        }},
        areWithoutExecutorVisible: {$set: !state.executors.areAllVisible}
      }});

    case actions.TOGGLE_SEARCH_MODE:
      let change = {$toggle: ['active']};

      if (state.search.active) {
        change.cardsId = {$set: []}
      }
      return update(state, {search: change});

    case actions.UPDATE_SEARCH_QUERY:
      const cardsId = [];

      state.lanes.forEach(lane => {
        lane.cards.forEach(card => {
          if (card.nodeBody.properties.global.title.toLowerCase().includes(payload))
            cardsId.push(card.id);
        })
      });
      return update(state, {search: {cardsId: {$set: cardsId}}});

    case 'ADD_CARD':
      return Lh.appendCardToLane(state, payload);

    case 'REMOVE_CARD':
      return Lh.removeCardFromLane(state, payload);

    case 'MOVE_CARD':
      return Lh.moveCardAcrossLanes(state, payload);

    case 'UPDATE_CARDS':
      return Lh.updateCardsForLane(state, payload);

    case 'UPDATE_LANES':
      return Lh.updateLanes(state, payload);

    case 'PAGINATE_LANE':
      return Lh.paginateLane(state, payload);

    case 'MOVE_LANE':
      return Lh.moveLane(state, payload);

    case 'TOGGLE_VISIBLE_LANE':
      return Lh.toggleLaneVisible(state, payload);

    case 'TOGGLE_VISIBLE_LANE_ALL':
      return Lh.toggleAllLanesVisible(state, payload);

    case 'UPDATE_LANE_WIDTH':
      return Lh.updateLaneWidth(state, payload);

    case 'CALCULATE_LANES_TIME_AND_NUMBER_TASKS':
      return Lh.calculateTimeAndNumberTasks(state);

    default:
      return state;
  }
};

export default boardReducer;
