import React from 'react'

import {LoaderDiv, LoadingBar} from '../styles/Loader'

const Loader = () => (
  <LoaderDiv>
    <LoadingBar />
    <LoadingBar />
    <LoadingBar />
    <LoadingBar />
  </LoaderDiv>
);

export default Loader;
