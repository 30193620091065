const DateFormatters = {
    t: (locale) => Intl.DateTimeFormat(locale, {hour: "2-digit", minute: "2-digit", second: "2-digit"}),
    ts: (locale) => Intl.DateTimeFormat(locale, {hour: "2-digit", minute: "2-digit"}),
    s: (locale) => Intl.DateTimeFormat(locale, {month: "short", year: "numeric"}),
    d: (locale) => Intl.DateTimeFormat(locale, {month: "short", day: "2-digit", year: "numeric"}),
    ds: (locale) => Intl.DateTimeFormat(locale, {month: "2-digit", day: "2-digit", year: "2-digit"}),
    dt: (locale) => Intl.DateTimeFormat(locale, {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }),
    exact: (locale) => Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }),
};

export function formatBy(locale, formatterType, value) {
    const formatter = DateFormatters[formatterType](locale);
    return formatter.format(new Date(value));
}

export default DateFormatters;
