import React, {Component} from 'react';
import uuidv1 from 'uuid/v1';

import BoardContainer from './BoardContainer';


export default class Board extends Component {
  constructor() {
    super();
    this.id = uuidv1();
  };

  render() {
    return (
        <BoardContainer {...this.props} id={this.id}/>
    );
  };
};
