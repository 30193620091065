/**
 * !!! Необходимо использовать функцию clearHtml перед вызовом
 *
 * Возвращает список строк соответствующий входному фрагменту html
 * Если указан maxLineCount, то из исходного html будет прочитано не более maxLineCount строк
 */
export function extractElementsText(s, maxLineCount = 0) {
    const span = document.createElement("span");
    span.innerHTML = s;

    function extractTextFromNodes(element, lines) {
        const text = [];
        let nonEmptyLines = 0;
        for (const childNode of element.childNodes) {
            const oldTextLength = text.length;
            let newTextLength = oldTextLength;
            if (childNode.childNodes.length >= 0 && childNode instanceof Element) {
                newTextLength = text.push(...extractTextFromNodes(childNode));
            } else {
                if (childNode.textContent) {
                    newTextLength = text.push(childNode.textContent);
                } else if (childNode instanceof HTMLElement && childNode.innerText) {
                    newTextLength = text.push(childNode.innerText);
                }
            }

            if (oldTextLength === newTextLength) continue;
            ++ nonEmptyLines;
            if (lines && nonEmptyLines === lines) break;
        }

        return text;
    }

    return extractTextFromNodes(span, maxLineCount);
}

/**
 * !!! Необходимо использовать функцию clearHtml перед вызовом
 *
 * Возвращает plain строку полученную из исходного html
 * Если указан maxLineCount, то из исходного html будет прочитано не более maxLineCount строк
 */
export function extractText(s, maxLineCount) {
    return extractElementsText(s, maxLineCount).join(" ");
}
