import {LoaderStyle} from "../../styles/Base";
import Spinner from "react-bootstrap/Spinner";
import React, {Component} from "react";
import {withNamespaces} from "react-i18next";

class LoaderSpinner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return <LoaderStyle>
            <Spinner animation="border" role="status">
                <span className="sr-only">{t`loading`}...</span>
            </Spinner>
        </LoaderStyle>;
    }
}

export default withNamespaces()(LoaderSpinner);
