import {createAction} from 'redux-actions';
import client from '../api/client';
import settings from '../settings';

export const loadBoard = createAction('LOAD_BOARD');

export const setTaskStatus = (cardId, toLaneId) => (dispatch, getState) => {
  const targetLane = getState().boardReducer.lanes.find(lane => lane.id === toLaneId);
  if (!targetLane) return;

  const newStatus = targetLane.title;

  dispatch(setTaskStatusReq());

  return client.post(`/batch`, [{
    url: `/nodes/${cardId}`,
    method: 'PATCH',
    body: JSON.stringify({
      properties: {
        update: [
          {
            group: 'byType',
            key: settings.KANBAN.TASK_NODE_TYPE.property_names.status,
            value: newStatus
          }
        ]
      }
    })
  }])
    .then(json => {
      dispatch(setTaskStatusResp(json));
    })
    .catch(error => {
      dispatch(setTaskStatusResp(error));
    })
};

export const setTaskStatusReq = createAction('SET_TASK_STATUS__REQ');

export const setTaskStatusResp = createAction('SET_TASK_STATUS__RESP');
