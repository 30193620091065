import _ from 'lodash';
import axios from 'axios';
import auth from 'auth';
import LocalStorageHelper from 'helpers/LocalStorageHelper';

const client = axios.create({
  baseURL: '/api',
  headers: {
      'Content-Type': 'application/json'
  },
  withCredentials: true
});

client.interceptors.request.use(config => {
  const basicAuthToken = auth.getBasicAuthToken(
    LocalStorageHelper.getUserEmail(),
    LocalStorageHelper.getUserPassword()
  );

  if (basicAuthToken) {
    config.headers.Authorization = basicAuthToken;
  }

  prepareBatchRequest(config);

  return config;
});

client.interceptors.response.use(
  resp => {
    return resp;
  },
  error => {
    if (error.response && error.response.status === 401) {
      auth.redirectToLogin();
    }
    return error;
  }
);

/**
 * Подготавливает batch-запрос:
 * 1) добавляет baseURL в начало урла каждого запроса (axios сам это не сделает, так как это тело запроса);
 */
const prepareBatchRequest = reqConfig => {
  if (/^\/batch\/*$/.test(reqConfig.url.trim())) {
    if (_.isArray(reqConfig.data)) {
      reqConfig.data.forEach(req => {
        if (req.url) {
          req.url = reqConfig.baseURL + req.url.replace(/^\/*/, '/');
        }
      });
    }
  }
};


export default client;
