import styled from 'styled-components';

export const BoardDiv = styled.div`
  padding: 5px;
  color: #393939;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Header = styled.header`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const Section = styled.div`
  display:flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 5px;
  position: relative;
  border-radius: 3px;
  background-color: #eee;
`;

export const LaneHeader = styled(Header)`
	padding: 0 5px;
	margin-bottom: 0;
	align-items: center;
	cursor: grab;
`;

export const LaneHeaderTitle = styled.div`
  flex: 1;
  padding-left: 5px;
  font-weight: bold;
  font-size: 15px;
`;

export const LaneHeaderNumberTasks = styled.span`
  font-weight: normal;
`;

export const LaneHeaderTime = styled.div`
  font-size: 13px;
`;

export const LaneFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  height: 10px;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  height: 10px;
`;

export const ScrollableLane = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex: 1 1 auto;
  
  border-top: 1px solid rgba(0,0,0,.1);
  overflow-y: auto;
  overflow-x: hidden;
  
  padding-bottom: ${props => (props.isDraggingOver ? '130px' : '0')};
  margin-top: 10px;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  cursor: grab;
  width: 50%;
`;

export const RightContent = styled.span`
  width: 50%;
  text-align: right;
  font-size: 13px;
`;

export const Category = styled.div`
  font-weight: bold;
  text-align: right;
  font-size: 12px;
  cursor: grab;
  padding-bottom: 12px;
  padding-right: 5px;
`;

export const CategoryListWrapper = styled.header`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end
`;

export const CardWrapper = styled.article`
  border-radius: 3px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  position: relative;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 7px;
`;

export const MovableCardWrapper = styled(CardWrapper)`
  opacity: ${props => props.isShadow ? 0.3 : 1};
  margin-left: ${props => props.margin}px;
  border-right: ${props => props.color ? '5px solid ' + props.color : 'initial'};
  &:hover {
    border-bottom: 1px solid #666;
  }
`;

export const CardHeader = styled(Header)`
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
`;

export const Executor = styled(Title)`
  color: #4d4d4d;
  font-size: 11px;
  font-weight: normal;
  line-height: 19px;
  width: 100%;
`;

export const CardTime = styled.span`
  white-space: nowrap;
  font-size: 10px;
`;

export const LaborCosts = styled.span`
  white-space: nowrap;
  font-size: 10px;
`;

export const CardTitleInfo = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center
`;

export const NodeLink = styled.a`
  margin-left: 0.5em;
  padding: 0 4px 0 4px;
  border-radius: 2px;
  
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const NodeLinkImg = styled.img`
  width: 10px;
  vertical-align: unset;
`;

export const NodeCommentCountWrapper = styled.div`
  font-size: 9px;
  margin-left: .5em;
`;

export const NodeCommentsCount = styled.div`
  visibility: ${ props => props.counter ? 'inherit' : 'hidden' };
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  border-radius: 50%;
  color: white;
`;

export const NodeUnreadCommentsCount = styled(NodeCommentsCount)`
  background-color: red;
`;

export const NodeTotalCommentsCount = styled(NodeCommentsCount)`
  margin-top: 2px;
  background-color: grey;
`;

export const Detail = styled.div`
  font-size: 12px;
  color: #4d4d4d;
  white-space: normal;
`;

export const Footer = styled.div`
  border-top: 1px solid #eee;
  padding-top: 6px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AddCardLink = styled.a`
  border-radius: 0 0 3px 3px;
  color: #838c91;
  display: block;
  padding: 5px 2px;
  position: absolute;
  text-decoration: none;
  cursor: pointer;
  bottom: 3px;

  &:hover {
    //background-color: #cdd2d4;
    color: #4d4d4d;
    text-decoration: underline;
  }
`;

export const LoaderStyle = styled.div`
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    & > div {
        width: 64px;
        height: 64px;
    }
`;

export const SimpleTooltip = styled.div`
  margin: 10px;
  padding: 5px;
  font-size: 12px;
  border: 1px solid rgba(51,51,51,.5);
  border-radius: 6px;
  background: #fff;
`;

export const CardTooltip = styled.div`
  margin: 10px;
  padding: 10px 15px;
  font-size: 13px;
  border: 1px solid rgba(51,51,51,.5);
  border-radius: 6px;
  background: #fff;
`;

export const SearchStyle = styled.div`
  position: relative;
  width: 200px;
  height: 38px;
  margin-left: 10px;
  box-sizing: border-box;
`;

export const SearchInput = styled.input`
  height: 38px;
  width: 200px;
  padding: 7px 36px 6px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  
  &:focus {
    border: 1px solid #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
    outline: 0;
  }
`;

export const SearchIcon = styled.img`
  height: 24px;
  top: 7px;
  right: 7px;
  position: absolute;
`;
