import update from 'immutability-helper';
import * as actions from '../actions/ViewActions';

const viewsReducer = (state = {
    currentView: 'Home'
}, action) => {
    const {payload, type} = action;
    switch (type) {
      case actions.CHANGE_VIEW:
        return update(state, {currentView: {$set: payload}});
      default:
          return state;
    }
};

export default viewsReducer;
