import client from '../api/client';
import queryString from 'query-string';

export const REQUEST_NODES = 'REQUEST_NODES';
export const requestNodes = () => {
    return {
        type: REQUEST_NODES
    };
};

export const RECEIVE_NODES = 'RECEIVE_NODES';
export const receiveNodesSuccess = (node) => {
    return {
        type: RECEIVE_NODES,
        payload: {
            node
        }
    };
};

export const RECEIVE_NODES_FAIL = 'RECEIVE_NODES_FAIL';
export function receiveNodesFailure(error) {
    return {
        type: RECEIVE_NODES_FAIL,
        error: true,
        payload: error
    };
}

export const FETCH_NODES = 'FETCH_NODES';
export const fetchNodes = (mapID, nodeID) => (dispatch, getState) => {
    dispatch(requestNodes());
    return client.get(`/maps/${mapID}/nodes/${nodeID}`).then(json => {
        dispatch(receiveNodesSuccess(json.data));
    }).catch(error => {
        dispatch(receiveNodesFailure(error));
    });
};

export const REQUEST_NODE_TYPES = 'REQUEST_NODE_TYPES';
export const requestNodeTypes = () => {
    return {
        type: REQUEST_NODE_TYPES
    };
};

export const NOT_PASSED_MAP_INFO = 'NOT_PASSED_MAP_INFO';
export const notPassedMapInfo = () => {
    return {
        type: NOT_PASSED_MAP_INFO,
        error: true,
        payload: new Error('Не заданы карта и корневой узел для отображения канбана')
    };
};

export const RECEIVE_NODE_TYPES = 'RECEIVE_NODE_TYPES';
export function receiveNodeTypesSuccess(types) {
    return {
        type: RECEIVE_NODE_TYPES,
        payload: types
    };
}

export const RECEIVE_NODE_TYPES_FAIL = 'RECEIVE_NODE_TYPES_FAIL';
export function receiveNodeTypesFailure(error) {
    return {
        type: RECEIVE_NODE_TYPES_FAIL,
        error: true,
        payload: error
    };
}

export const FETCH_NODE_TYPES = 'FETCH_NODE_TYPES';
export const fetchNodeTypes = (mapId) => (dispatch, getState) => {
    dispatch(requestNodeTypes());
    return client.get(`/maps/${mapId}/node_types`).then(json => {
        dispatch(receiveNodeTypesSuccess(json.data));
    }).catch(error => {
        dispatch(receiveNodeTypesFailure(error));
    });
};

export const REQUEST_MAP_USERS = 'REQUEST_MAP_USERS';
export function requestMapUsers() {
    return {
        type: REQUEST_MAP_USERS
    };
}

export const RECEIVE_MAP_USERS = 'RECEIVE_MAP_USERS';
export function receiveMapUsers(users) {
    return {
        type: RECEIVE_MAP_USERS,
        payload: users
    };
}

export const RECEIVE_MAP_USERS_FAIL = 'RECEIVE_MAP_USERS_FAIL';
export function receiveMapUsersFail(error) {
    return {
        type: RECEIVE_MAP_USERS_FAIL,
        error: true,
        payload: error
    };
}

export const FETCH_MAP_USERS = 'FETCH_MAP_USERS';
export const fetchMapUsers = (mapId) => (dispatch, getState) => {
    dispatch(requestMapUsers());

    return client.get(`/maps/${mapId}/users`).then(json => {
       dispatch(receiveMapUsers(json.data));
    }).catch(error => {
       dispatch(receiveMapUsersFail(error));
    });
};

export const TOGGLE_EXECUTOR_VISIBLE = 'TOGGLE_EXECUTOR_VISIBLE';
export function toggleExecutorVisible(username) {
    return {
        type: TOGGLE_EXECUTOR_VISIBLE,
        payload: username
    };
}

export const TOGGLE_WITHOUT_EXECUTOR_VISIBLE = 'TOGGLE_WITHOUT_EXECUTOR_VISIBLE';
export function toggleWithoutExecutorVisible() {
    return {
        type: TOGGLE_WITHOUT_EXECUTOR_VISIBLE
    };
}

export const TOGGLE_EXECUTOR_VISIBLE_ALL = 'TOGGLE_EXECUTOR_VISIBLE_ALL';
export function toggleExecutorVisibleAll() {
    return {
        type: TOGGLE_EXECUTOR_VISIBLE_ALL
    };
}

export const TOGGLE_SEARCH_MODE = 'TOGGLE_SEARCH_MODE';
export function toggleSearchMode() {
    return {
        type: TOGGLE_SEARCH_MODE
    };
}

export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export function updateSearchQuery(searchQuery) {
    return {
        type: UPDATE_SEARCH_QUERY,
        payload: searchQuery
    };
}


export const saveExecutors = () => (dispatch, getState) => {
    const state = getState();
    const query = queryString.parse(window.location.search);

    const executors = state.boardReducer.executors.byEmail;
    const visibleExecutors = Object.entries(executors)
        .filter(e => e[1].isVisible)
        .map(e => e[0]);
    query["executors"] = visibleExecutors.join(",");

    const withoutExecutor = state.boardReducer.executors.areWithoutExecutorVisible;
    query['withoutExecutor'] = withoutExecutor ? true : undefined;

    // убрать фильтры, если мы вернулись в начальное состояние
    if (visibleExecutors.length === Object.keys(executors).length && withoutExecutor) {
        delete query["executors"];
        delete query["withoutExecutor"];
    }

    const queryStr = queryString.stringify(query);
    window.history.replaceState(null, null, `/?${queryStr}`);
};

export const updateExecutorsFromQuery = (query) => (dispatch, getState) => {
    const queryExecutors = (query.executors || '').split(',');
    const state = getState();
    const executors = state.boardReducer.executors.byEmail;
    for (const email of Object.keys(executors)) {
        if (!queryExecutors.includes(email)) {
            dispatch(toggleExecutorVisible(email));
        }
    }
    if (!query.withoutExecutor) {
        dispatch(toggleWithoutExecutorVisible());
    }
};
