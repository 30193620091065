import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {HomeContainer} from '../components/Home/HomeContainer';
import {SettingsContainer} from "../components/Settings/SettingsContainer";

export const MainRoutes = () => (
  <Switch>
    <Route exact path="/" component={HomeContainer} />
    <Route path="/settings" component={SettingsContainer} />
  </Switch>
);

export default MainRoutes;
