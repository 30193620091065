import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from './Header';

function mapStateToProps(state, ownProps) {
  return {
    rootInfo: state.boardReducer.rootInfo,
    currentView: state.viewsReducer.currentView
  }
}

export const HeaderContainer = connect(mapStateToProps)(withRouter(Header));
