import {connect} from 'react-redux';
import CardDetails from "./CardDetails";

const mapStateToProps = (state) => {
    const { taskNodeType } = state.boardReducer.nodeTypes;
    const { byId: usersById } = state.boardReducer.users;
    return {
        taskNodeType,
        usersById
    }
};

export default connect(mapStateToProps)(CardDetails);
