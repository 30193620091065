import { connect } from 'react-redux';

import Statuses from './Statuses';
import {moveLane, saveLanes, toggleVisibleLane, toggleVisibleLaneAll} from '../../actions/LaneActions';

function mapStateToProps(state, ownProps) {
    return {
        lanes: state.boardReducer.lanes,
        areAllLanesVisible: state.boardReducer.areAllLanesVisible
    };
}

function mapStateToDispatch(dispatch) {
    return {
        onMoveLane: (oldIndex, newIndex) => {
            dispatch(moveLane(oldIndex, newIndex));
            dispatch(saveLanes());
        },
        onToggleVisibleLane: (index) => {
            dispatch(toggleVisibleLane(index));
            dispatch(saveLanes());
        },
        onToggleVisibleLaneAll: (isVisible) => {
            dispatch(toggleVisibleLaneAll(isVisible));
            dispatch(saveLanes());
        },
    };
}

export const StatusesContainer = connect(mapStateToProps, mapStateToDispatch)(Statuses);

