import { connect } from 'react-redux';
import Executors from './Executors';
import {
    saveExecutors,
    toggleExecutorVisible,
    toggleExecutorVisibleAll,
    toggleWithoutExecutorVisible
} from '../../actions/KanbanAction';
import {calculateLanesTimeAndNumberTasks} from '../../actions/LaneActions';

function mapStateToProps(state, ownProps) {
    return {
        executors: state.boardReducer.executors.byEmail,
        areWithoutExecutorVisible: state.boardReducer.executors.areWithoutExecutorVisible,
        areAllVisible: state.boardReducer.executors.areAllVisible
    };
}

function mapStateToDispatch(dispatch) {
    return {
        onToggleExecutorVisible: (username) => {
            dispatch(toggleExecutorVisible(username));
            dispatch(saveExecutors());
        },
        onToggleWithoutExecutorVisible: () => {
            dispatch(toggleWithoutExecutorVisible());
            dispatch(saveExecutors());
        },
        onToggleExecutorVisibleAll: () => {
            dispatch(toggleExecutorVisibleAll());
            dispatch(saveExecutors());
        },
        onCalculateLanesTimeAndNumberTasks: () => {
            dispatch(calculateLanesTimeAndNumberTasks());
        }
    };
}

export const ExecutorsContainer = connect(mapStateToProps, mapStateToDispatch)(Executors);

