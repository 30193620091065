import React from 'react';
import ReactDOM from 'react-dom';
import {unregister} from './registerServiceWorker';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';

import App from './App';
import Login from './components/Login';
import rootReducer from './reducers/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import i18n from "./i18n";
import {I18nextProvider} from "react-i18next";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store =  createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware))
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div style={{height: '100%'}}>
        <Switch>
          <Route path="/login" component={Login} />
          <I18nextProvider i18n={ i18n }>
            <App />
          </I18nextProvider>,
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

try {
  unregister();
} catch (e) { console.error(e) }
