import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import XHR from "i18next-xhr-backend";
import {reactI18nextModule} from "react-i18next";
import {formatBy} from "./date-formatter";

const fallbackLng = "en-US";

const i18n = i18next.use(Backend).use(LanguageDetector).use(reactI18nextModule);
i18n.init({
    react: {
        wait: true,
        useSuspense: false,
    },
    backend: {
        backends: [
            XHR,
        ],
        backendOptions: [{
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        }],
    },
    fallbackLng,
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            console.log(value);
            if (format && format.startsWith("intlDate")) {
                const locale = lng || i18next.language;
                const formatterType = format.split(".")[1];
                return formatBy(locale, formatterType, value);
            }

            return value;
        },
    },
    // не пытаться загружать translation.json (не существует)
    ns: [],
    defaultNS: "common",
});

export default i18n;
