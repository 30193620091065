import React, {Component} from 'react';
import uuidv1 from 'uuid/v1';

import Draggable from '../../dnd/Draggable';
import Container from '../../dnd/Container';
import {withNamespaces} from "react-i18next";


class Statuses extends Component {
    constructor(props) {
        super(props);
        this.id = uuidv1();

        this.toggleLaneVisible = this.toggleLaneVisible.bind(this);
        this.toggleLaneVisibleAll = this.toggleLaneVisibleAll.bind(this);
    };

    onLaneDrop = ({removedIndex, addedIndex, payload}) => {
        this.props.onMoveLane({oldIndex: removedIndex, newIndex: addedIndex});
    };

    toggleLaneVisible(index) {
        this.props.onToggleVisibleLane({index: index});
    };

    toggleLaneVisibleAll(event) {
        this.props.onToggleVisibleLaneAll({isVisible: event.target.checked});
    };

    render () {
        const {t, lanes} = this.props;

        return (
            <div style={{width: '50%'}}>
                {this.props.lanes.length > 0 &&
                <div>
                    <h1>{t`statuses.label`}: </h1>
                    <Container
                        style={{width: '50%', alignItems: 'flex-start'}}
                        lockAxis={'y'}
                        onDrop={this.onLaneDrop}
                    >
                        {lanes.map((lane, index) => {
                            return (
                                <Draggable key={index} style={{width: '100%'}}>
                                    <div className="status">
                                        <input type="checkbox"
                                               id={`lane_${lane.id}`}
                                               checked={lane.visible}
                                               onChange={() => this.toggleLaneVisible(index)}
                                        />
                                        <label htmlFor={`lane_${lane.id}`}>
                                           <img className="icon" src={lane.icon} alt="" />
                                           {lane.title || t`statuses.not_set`}
                                        </label>
                                    </div>
                                </Draggable>
                            )
                        })}
                    </Container>
                    <div className="status">
                        <input type="checkbox"
                               id="are-all-lanes-visible"
                               checked={this.props.areAllLanesVisible}
                               onChange={this.toggleLaneVisibleAll}
                        />
                        <label htmlFor="are-all-lanes-visible">
                            {t`statuses.toggle`}
                        </label>
                    </div>
                </div>}
            </div>
        );
    };
};

export default withNamespaces()(Statuses);

