import _ from 'lodash';
import update from 'immutability-helper';
import uuidv1 from 'uuid/v1';

import settings from '../settings';
import buildUrl from 'build-url';
import queryString from 'query-string';

const LaneHelper = {
  createLanes: (node, state) => {
    const savedOrder = state.taskStatuses.order;
    const savedVisibility = state.taskStatuses.visibility;
    const savedWidth = state.taskStatuses.width;
    const taskNodePropertyNames = settings.KANBAN.TASK_NODE_TYPE.property_names;

    const getOrAddLane = name => {
      if (!lanesById[name]) {
        lanesById[name] = {
          id: uuidv1(),
          title: name,
          icon: state.taskStatuses.icons[name],
          visible: savedVisibility[name] !== false, // либо true, либо undefined
          width: savedWidth[name] || 350,
          spendTime: 0,
          laborCosts: 0,
          numberTasks: 0,
          cards: []
        };
      }

      return lanesById[name];
    };

    const addTask = (task, parentTaskId, categories) => {
      const { id, map_id: mapId, body } = task;
      const { properties, id: bodyId, comments_count, unread_comments_count } = body;
      const comments = {
        total: comments_count,
        unread: unread_comments_count
      };

      const lane = getOrAddLane(properties.byType[taskNodePropertyNames.status] || ""); // undefined -> ""

      const laborCosts = parseFloat(String(properties.byType[taskNodePropertyNames.labor_costs]).replace(',', '.')) || 0;
      const spendTime = parseFloat(String(properties.byType[taskNodePropertyNames.spend_time]).replace(',', '.')) || 0;

      const nodeUrl = buildUrl(settings.RF_APP_BASE_URL, {
        hash: `mindmap?${queryString.stringify({mapid: mapId, nodeid: id})}&opened`
      });

      let executorEmail = properties.byType[taskNodePropertyNames.assignee];
      if (!Object.values(state.users.byId).find(user => user.username === executorEmail))
        executorEmail = "";

      const isNodeLink = id !== bodyId;

      const card = {
        id,
        mapId,
        nodeUrl,
        comments,
        color: isNodeLink ? task.properties.style.color : properties.style.color,
        nodeBody: body,
        laneId: lane.id,
        title: properties.global.title,
        description: properties.byType[taskNodePropertyNames.description],
        spendTime: spendTime,
        laborCosts: laborCosts,
        executorEmail: executorEmail,
        categories: [...categories],
        parentCardId: parentTaskId,
        originalCardId: bodyId,
        isNodeLink: isNodeLink
      };

      const includeCard = lane.cards.some(card => card.originalCardId === bodyId);
      lane.cards.push(card);

      if (!includeCard) {
        lane.spendTime += spendTime;
        lane.laborCosts += laborCosts;
        lane.numberTasks += 1;
      }
    };

    const parseTaskNode = (node, parentNode, categories=[]) => {
      if (node) {
        if (('body' in node) && ('type_id' in node.body) && (node.body.type_id === taskNodeType.id)) {
          const parentTaskId = (parentNode && parentNode.body && parentNode.body.type_id === taskNodeType.id) ? parentNode.id : null;
          addTask(node, parentTaskId, categories);
        }

        if (('body' in node) && ('type_id' in node.body) && (node.body.type_id !== taskNodeType.id)
          && (node.body.properties !== null)) {
          categories.push(node.body.properties.global.title);
        }

        if ((('children' in node) && (node.children.length > 0)) ||
            (('children' in node.body) && (node.body.children.length > 0))) {
          const children = ('children' in node) ? node.children : node.body.children;
          children.forEach(function(item, i, arr) {
            parseTaskNode(item, node, [...categories]);
          });
        }
      }
    };

    const lanesById = {};
    const {taskNodeType} = state.nodeTypes;

    savedOrder.forEach(laneName => {
      getOrAddLane(laneName);
    });

    parseTaskNode(node.body, null);
    return savedOrder.map((laneName) => lanesById[laneName]).filter(lane => lane);
  },

  paginateLane: (state, {laneId, newCards, nextPage}) => {
    const updatedLanes = LaneHelper.appendCardsToLane(state, {laneId: laneId, newCards: newCards});
    updatedLanes.find(lane => lane.id === laneId).currentPage = nextPage;
    return update(state, {lanes: {$set: updatedLanes}});
  },

  appendCardsToLane: (state, {laneId, newCards, index = null}) => {
    newCards = newCards.map(card => update(card, {laneId: {$set: laneId}}));
    const laneIndex = state.lanes.findIndex(lane => lane.id === laneId);
    if (index === null) {
      index = state.lanes[laneIndex].cards.length;
    }

    return update(state, {lanes: {[laneIndex]: {cards: {$splice: [[index, 0, ...newCards]]}}}});
  },

  appendCardToLane: (state, {laneId, card, index}) => {
    return LaneHelper.appendCardsToLane(state, {laneId: laneId, newCards: [card], index});
  },

  removeCardFromLane: (state, {laneId, cardId}) => {
    const lanes = state.lanes.map(lane => {
      if (lane.id === laneId) {
        const newCards = lane.cards.filter(card => card.id !== cardId);
        return update(lane, {cards: {$set: newCards}});
      } else {
        return lane;
      }
    });
    return update(state, {lanes: {$set: lanes}});
  },

  moveCardAcrossLanes: (state, {fromLaneId, toLaneId, cardId, index}) => {
    let toLaneIndex = null;
    let spendTime = 0;
    let laborCosts = 0;
    let cardsToMove = [];

    let interimLanes = state.lanes.map((lane, index) => {
      if (lane.id === fromLaneId) {
        const cardToMove = lane.cards.find(card => card.id === cardId);
        cardsToMove = LaneHelper.findRelatedCards(lane, cardToMove);

        const newCards = lane.cards.filter(card => {
          return !cardsToMove.some(cardToMove => cardToMove.id === card.id);
        });
        spendTime = cardToMove.spendTime;
        laborCosts = cardToMove.laborCosts;

        return update(lane, {
          cards: {$set: newCards},
          spendTime: {$set: lane.spendTime - spendTime},
          laborCosts: {$set: lane.laborCosts - laborCosts},
          numberTasks: {$set: lane.numberTasks - 1}});
      } else {
        if (lane.id === toLaneId) {
          toLaneIndex = index;
        }
        return lane;
      }
    });

    interimLanes[toLaneIndex].spendTime += spendTime;
    interimLanes[toLaneIndex].laborCosts += laborCosts;
    interimLanes[toLaneIndex].numberTasks += 1;
    const updatedState = update(state, {lanes: {$set: interimLanes}});
    return LaneHelper.appendCardsToLane(updatedState, {laneId: toLaneId, newCards: cardsToMove, index: index});
  },

  updateCardsForLane: (state, {laneId, cards}) => {
    const laneIndex = state.lanes.findIndex(lane => lane.id === laneId);
    if (laneIndex) {
      return update(state, {lanes: {[laneIndex]: {cards: {$set: cards}}}});
    }
  },

  updateLanes: (state, lanes) => {
    return update(state, {lanes: {$set: lanes}});
  },

  moveLane: (state, {oldIndex, newIndex}) => {
    const laneToMove = state.lanes[oldIndex];
    const statusToMove = state.taskStatuses.order[oldIndex];

    return update(state, {
      lanes: {$splice: [[oldIndex, 1], [newIndex, 0, laneToMove]]},
      taskStatuses: {order: {$splice: [[oldIndex, 1], [newIndex, 0, statusToMove]]}}});
  },

  toggleLaneVisible: (state, {index}) => {
    const visibility = {...state.taskStatuses.visibility,
      [state.taskStatuses.order[index]]: !state.taskStatuses.visibility[state.taskStatuses.order[index]] };

    return update(state, {
      lanes: {[index]: {$toggle: ['visible']}},
      taskStatuses: {$merge: {visibility: visibility}}});
  },

  toggleAllLanesVisible: (state, {isVisible}) => {
    const visibility = Object.fromEntries(state.taskStatuses.order.map(s => [s, isVisible]));

    return update(state, {
      areAllLanesVisible: {$set: isVisible},
      lanes: {$apply: lanes => {
        return lanes.map(lane => {return update(lane, {visible: {$set: isVisible}})})
      }},
      taskStatuses: {$merge: {visibility: visibility}}
    });
  },

  updateLaneWidth: (state, {title, width}) => {
    const index = state.taskStatuses.order.indexOf(title);
    const statusesWidth = {...state.taskStatuses.width,
      [state.taskStatuses.order[index]]: width};

    return update(state, {
      lanes: {[index]: {$merge: {width: width}}},
      taskStatuses: {$merge: {width: statusesWidth}}});
  },

  calculateTimeAndNumberTasks: (state) => {
    const newLanes = state.lanes.map(lane => {
      let spendTime = 0;
      let laborCosts = 0;
      let numberTasks = 0;

      lane.cards.forEach(card => {
        if (((card.executorEmail && state.executors.byEmail[card.executorEmail].isVisible)
                || (!card.executorEmail && state.executors.areWithoutExecutorVisible))
            && (!card.isNodeLink
                || (card.isNodeLink && !lane.cards.some(c => c.id === card.originalCardId)))
            && (!state.search.active
                || state.search.cardsId.includes(card.id))) {
            spendTime += card.spendTime;
            laborCosts += card.laborCosts;
            numberTasks += 1;
          }
      });

      return update(lane, {
        spendTime: {$set: spendTime},
        laborCosts: {$set: laborCosts},
        numberTasks: {$set: numberTasks}});
    });
    return update(state, {lanes: {$set: newLanes}});
  },

  findRelatedCards: (lane, card) => {
    if (card.isNodeLink) {
      return lane.cards.filter(elem =>elem.id === card.originalCardId || elem.originalCardId === card.originalCardId);
    } else {
      return lane.cards.filter(elem => elem.originalCardId === card.id);
    }
  }
};

export default LaneHelper;
