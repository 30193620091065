import {connect} from "react-redux";
import Search from "./Search";
import {toggleSearchMode, updateSearchQuery} from "../../actions/KanbanAction";
import {calculateLanesTimeAndNumberTasks} from "../../actions/LaneActions";

function mapStateToProps(state) {
    return {
        active: state.boardReducer.search.active,
        cardsId: state.boardReducer.search.cardsId,
    };
}

function mapStateToDispatch(dispatch) {
    return {
        onToggleSearchMode: () => {
            dispatch(toggleSearchMode());
            dispatch(calculateLanesTimeAndNumberTasks());
        },
        onToggleSearchQueryMode: (searchQuery) => {
            dispatch(updateSearchQuery(searchQuery));
            dispatch(calculateLanesTimeAndNumberTasks());
        },
    };
}

export const SearchContainer = connect(mapStateToProps, mapStateToDispatch)(Search);
