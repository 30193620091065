import { connect } from 'react-redux';

import Settings from './Settings';
import {changeView} from '../../actions/ViewActions';

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.boardReducer.nodeTypes.isFetching || state.boardReducer.isFetching
  };
}

function mapStateToDispatch(dispatch) {
  return {
    onChangeView: (viewName) => {
      dispatch(changeView(viewName));
    }
  };
}

export const SettingsContainer = connect(mapStateToProps, mapStateToDispatch)(Settings);
