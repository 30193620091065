import buildUrl from 'build-url';
import React, {Component} from 'react';
import { connect} from 'react-redux';
import queryString from 'query-string';
import { hot } from 'react-hot-loader/root';

import './styles/css/app.css';
import settings from './settings';
import {
    fetchMapUsers,
    fetchNodes,
    fetchNodeTypes,
    notPassedMapInfo,
    updateExecutorsFromQuery
} from './actions/KanbanAction';
import {HeaderContainer} from './components/Header/HeaderContainer';
import MainRoutes from './routes/MainRoutes';
import LocalStorageHelper from './helpers/LocalStorageHelper';
import {calculateLanesTimeAndNumberTasks} from "./actions/LaneActions";

const queryArgs = queryString.parse(window.location.search);
const mapId = queryArgs.mapid || LocalStorageHelper.getMapId();
const nodeId = queryArgs.nodeid || LocalStorageHelper.getNodeId();
const executorsQuery = queryArgs.executors;
const withoutExecutorQuery = queryArgs.withoutExecutor;

class App extends Component {
    constructor() {
        super();

        this.rootNodeUrl = buildUrl(settings.RF_APP_BASE_URL, {
            hash: `mindmap?${queryString.stringify({mapid: mapId, nodeid: nodeId})}`
        });
    };

    async componentDidMount() {
        if (this.props.lanes.length > 0) {
            // fixme костыль чтобы не перезагружалось состояние при hot-reload
            return;
        }

        if (mapId && nodeId) {
            LocalStorageHelper.setMapId(mapId);
            LocalStorageHelper.setNodeId(nodeId);

            await Promise.all([
                this.props.fetchNodeTypes(mapId),
                this.props.fetchMapUsers(mapId),
                this.props.fetchNodes(mapId, nodeId),
            ]);
        } else {
            this.props.notPassedMapInfo();
        }

        if (executorsQuery !== undefined || withoutExecutorQuery !== undefined) {
            await this.props.updateExecutorsFromQuery(queryArgs);
            this.props.onCalculateLanesTimeAndNumberTasks();
        }
    };

    render() {
        return (
            <div className="App">
                <HeaderContainer rootNodeUrl={this.rootNodeUrl}/>
                <MainRoutes />
            </div>
        );
    };
}

function mapStateToProps(state) {
    return {
        areNodesLoading: state.boardReducer.isFetching,
        //node: state.boardReducer.node,
        rootInfo: state.boardReducer.rootInfo,
        nodeTypes: state.boardReducer.nodeTypes,
        lanes: state.boardReducer.lanes,
        isFailed: state.boardReducer.isFailed,
        errorText: state.boardReducer.errorText,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNodes: (mapId, nodeId) => {
            return dispatch(fetchNodes(mapId, nodeId));
        },
        fetchNodeTypes: (mapId) => {
            return dispatch(fetchNodeTypes(mapId));
        },
        fetchMapUsers: (mapId) => {
            return dispatch(fetchMapUsers(mapId));
        },
        notPassedMapInfo: () => {
            return dispatch(notPassedMapInfo());
        },
        updateExecutorsFromQuery: (query) => {
            return dispatch(updateExecutorsFromQuery(query));
        },
        onCalculateLanesTimeAndNumberTasks: () => {
            dispatch(calculateLanesTimeAndNumberTasks());
        }
    };
}

App = connect(mapStateToProps, mapDispatchToProps)(App);

// noinspection JSUnresolvedVariable,ES6ModulesDependencies
export default process.env.NODE_ENV === "development" ? hot(App) : App;
