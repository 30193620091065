import React, {Component} from 'react';
import uuidv1 from 'uuid/v1';

import {StatusesContainer} from '../Statuses/StatusesContainer';
import LoaderSpinner from "../LoaderSpinner/LoaderSpiner";


export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.id = uuidv1();
    };

    componentDidMount() {
        this.props.onChangeView('Settings');
    };

    render () {
        return (
            <div className="settings">
                {this.props.isFetching
                    ? <LoaderSpinner/>
                    : <StatusesContainer/>}
            </div>
        );
    };
};
