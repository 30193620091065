import CryptoJS from 'crypto-js';
import axios from 'axios';
import $ from 'jquery';
import queryString from 'query-string';
import client from './api/client';

import LocalStorageHelper from './helpers/LocalStorageHelper';

const auth = {

    login: function (username, pass) {
        if (username && pass) {
            this.checkAuth(username, pass);
        } else {
            $('#login-error-text').toggle().delay(2000).fadeOut();
        }
    },

    logout: async function () {
        try {
            await client.get('logout');
        } finally {
            localStorage.clear();
            delete axios.defaults.headers.common['Authorization'];
            auth.redirectToLogin();
        }
    },

    redirectAfterLogin: function () {
        window.location.replace(queryString.parse(window.location.search).next || '/');
    },

    redirectToLogin: function () {
        window.location.replace('/login?next=' + encodeURIComponent(window.location));
    },

    getBasicAuthToken: function (email, password) {
        return (email && password) ? `Basic ${btoa(encodeURI(`${email}:${password}`))}` : null;
    },

    checkAuth: function (username, password) {
        const loginErrorText = $('#login-error-text');
        loginErrorText.text('Загрузка...');
        loginErrorText.show();

        client.post('/login', {
            username: username,
            password: password
        }, {headers: {
            Authorization: auth.getBasicAuthToken(username, CryptoJS.MD5(password))
        }}).then(resp => {
            LocalStorageHelper.setUserEmail(username);
            LocalStorageHelper.setUserPassword(CryptoJS.MD5(password));
            auth.redirectAfterLogin();
        }).catch(error => {
            loginErrorText.text('Ошибка авторизации');
            loginErrorText.show().delay(2000).fadeOut();
        });
    },
};

export default auth;
