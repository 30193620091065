import React, {Component} from 'react';
import defaultAvatarIcon from '../../images/default-user.svg';
import styled from "styled-components";
import Select from "react-select";
import {withNamespaces} from "react-i18next";

const ExecutorsRoot = styled.div`
    display: flex;
    align-items: center;
`;

const SelectWrapper = styled.div`
    margin-left: 8px;
    min-width: 400px;
    max-width: 600px;
    color: black;
`;

const WITHOUT_EXECUTOR_VALUE = 'WITHOUT_EXECUTOR_VALUE';
const SELECT_ALL_VALUE = 'SELECT_ALL_VALUE';

const styles = {
    multiValueLabel(styles, { data }) {
        if (data.avatar === '') {
            return styles;
        }
        return {
            ...styles,
            color: 'transparent',
            width: '24px',
            backgroundImage: `url(${data.avatar || defaultAvatarIcon})`,
            backgroundSize: '24px',
            backgroundRepeat: 'no-repeat',
        };
    },

    option(styles, { data }) {
        const image = data.avatar === '' ? null : `url(${data.avatar || defaultAvatarIcon})`;
        return {
            ...styles,
            paddingLeft: '40px',
            backgroundImage: image,
            backgroundPosition: '8px',
            backgroundSize: '24px',
            backgroundRepeat: 'no-repeat',
        }
    }
};

class Executors extends Component {
    constructor(props) {
        super(props);
    }

    render () {
        const {t, executors} = this.props;
        const options = Object.entries(executors).map(([username, executor]) => ({
            label: username,
            value: username,
            avatar: executor.avatar,
            visible: executor.isVisible,
        }));
        options.push({
            label: t`assignee.not_set`,
            value: WITHOUT_EXECUTOR_VALUE,
            avatar: '',
            visible: this.props.areWithoutExecutorVisible,
        });

        const allSelected = Object.values(executors).every(e => e.isVisible);
        if (!allSelected || !this.props.areWithoutExecutorVisible) {
            options.push({
                label: t`assignee.select_all`,
                value: SELECT_ALL_VALUE,
                avatar: '',
                visible: false,
            });
        }

        const value = options.filter(o => o.visible);

        return <ExecutorsRoot>
            <SelectWrapper>
                <Select
                    placeholder={t`select.assignee`}
                    noOptionsMessage={() => t`select.empty`}
                    options={options}
                    value={value}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    styles={styles}
                    onChange={(_, e) => this.changed(e)}
                />
            </SelectWrapper>
        </ExecutorsRoot>;
    }

    changed(e) {
        // todo перенести в store
        const executors = this.props.executors;

        switch (e.action) {
            case "clear":
                for (const [username, executor] of Object.entries(executors)) {
                    if (executor.isVisible) {
                        this.props.onToggleExecutorVisible(username);
                    }
                }
                if (this.props.areWithoutExecutorVisible) {
                    this.props.onToggleWithoutExecutorVisible();
                }
                break;
            case "select-option":
                if (e.option.value === WITHOUT_EXECUTOR_VALUE) {
                    this.props.onToggleWithoutExecutorVisible();
                } else if (e.option.value === SELECT_ALL_VALUE) {
                    for (const [username, executor] of Object.entries(executors)) {
                        if (!executor.isVisible) {
                            this.props.onToggleExecutorVisible(username);
                        }
                    }
                    if (!this.props.areWithoutExecutorVisible) {
                        this.props.onToggleWithoutExecutorVisible();
                    }
                } else {
                    this.props.onToggleExecutorVisible(e.option.value);
                }
                break;
            case "remove-value":
                if (e.removedValue.value === WITHOUT_EXECUTOR_VALUE) {
                    this.props.onToggleWithoutExecutorVisible();
                } else {
                    this.props.onToggleExecutorVisible(e.removedValue.value);
                }
                break;
        }

        this.props.onCalculateLanesTimeAndNumberTasks();
    }
};

export default withNamespaces()(Executors);

