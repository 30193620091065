import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import isEqual from 'lodash/isEqual'
import update from 'immutability-helper';
import uuidv1 from 'uuid/v1'

import Container from '../dnd/Container'
import Draggable from '../dnd/Draggable'
import Loader from './Loader'
import Card from './Card'
import NewCard from './NewCard'
import {
    AddCardLink,
    Category,
    CategoryListWrapper,
    LaneFooter,
    LaneHeader,
    LaneHeaderNumberTasks,
    LaneHeaderTime,
    LaneHeaderTitle,
    ScrollableLane,
    Section,
    SimpleTooltip
} from '../styles/Base'

import * as laneActions from '../actions/LaneActions'
import * as kanbanActions from '../actions/KanbanAction'
import {CollapseBtn, ExpandBtn} from '../styles/Elements'
import * as boardActions from '../actions/BoardActions';
import {NON_DRAG_SELECTOR} from "../constants";
import AppPopper from "./AppPopper/AppPopper";
import {Resizable} from "re-resizable";
import TimePopper from "./widgets/TimePopper";
import {withNamespaces} from "react-i18next";
import {clearHtml} from "../utils/clear-html";
import {extractText} from "../utils/extract-text";

class Lane extends Component {
  state = {
    loading: false,
    currentPage: this.props.currentPage,
    addCardMode: false,
    collapsed: false,
    isDraggingOver: false
  };

  handleScroll = evt => {
    const node = evt.target;
    const elemScrolPosition = node.scrollHeight - node.scrollTop - node.clientHeight;
    const {onLaneScroll} = this.props;
    if (elemScrolPosition <= 0 && onLaneScroll && !this.state.loading) {
      const {currentPage} = this.state;
      this.setState({loading: true});
      const nextPage = currentPage + 1;
      onLaneScroll(nextPage, this.props.id).then(moreCards => {
        if (!moreCards || moreCards.length === 0) {
          // if no cards present, stop retrying until user action
          node.scrollTop = node.scrollTop - 100;
        } else {
          this.props.actions.paginateLane({
            laneId: this.props.id,
            newCards: moreCards,
            nextPage: nextPage
          });
        }
        this.setState({loading: false});
      })
    }
  };

  laneDidMount = node => {
    if (node) {
      node.addEventListener('scroll', this.handleScroll);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.cards, nextProps.cards)) {
      this.setState({
        currentPage: nextProps.currentPage
      });
    }
  };

  showEditableCard = () => {
    this.setState({addCardMode: true});
  };

  hideEditableCard = () => {
    this.setState({addCardMode: false});
  };

  addNewCard = params => {
    const laneId = this.props.id;
    const id = uuidv1();
    this.hideEditableCard();
    const card = update(params, {$push: id});
    this.props.actions.addCard({laneId, card});
  };

  renderAddCardLink = () => {
    const {addCardLink} = this.props;
    if (addCardLink) {
      return <span onClick={this.showEditableCard}>{addCardLink}</span>;
    } else {
      return <AddCardLink onClick={this.showEditableCard}>Add Card</AddCardLink>;
    }
  };

  renderNewCard = () => {
    const {newCardTemplate, id} = this.props;
    if (newCardTemplate) {
      const newCardWithProps = React.cloneElement(newCardTemplate, {
        onCancel: this.hideEditableCard,
        onAdd: this.addNewCard,
        laneId: id
      });
      return <span>{newCardWithProps}</span>;
    } else {
      return <NewCard onCancel={this.hideEditableCard} onAdd={this.addNewCard} />;
    }
  };

  onDragStart = ({payload}) => {
    const {handleDragStart} = this.props;
    handleDragStart && handleDragStart(payload.id, payload.laneId);
  };

  shouldAcceptDrop = sourceContainerOptions => {
    return this.props.droppable && sourceContainerOptions.groupName === 'TrelloLane';
  };

  onDragEnd = (laneId, result) => {
    const {handleDragEnd, actions} = this.props;
    const {addedIndex, payload} = result;

    if (addedIndex !== null) {
      if (payload.laneId !== laneId) {
          actions.setTaskStatus(payload.originalCardId, laneId);
          this.props.actions.moveCardAcrossLanes({
            fromLaneId: payload.laneId,
            toLaneId: laneId,
            cardId: payload.id,
            index: addedIndex
          })
      }
      handleDragEnd && handleDragEnd(payload.id, payload.laneId, laneId, addedIndex, payload);
    }
  };

  onUpdateLaneWidth = (title, width) => {
    const {actions} = this.props;
    actions.updateLaneWidth({title, width});
    actions.saveLanes();
  };

  renderDragContainer = isDraggingOver => {
    const {
      editable,
      hideCardDeleteIcon,
      draggable,
      cardDraggable,
      cards,
      cardDragClass,
      isVisibleWithoutExecutor,
      customCardLayout,
      executors,
      searchActive,
      searchCardsId,
      children,
      id
    } = this.props;

    let cardIndex = -1;

    const isVisible = (val) => {
        if(((val.card.executorEmail && executors[val.card.executorEmail].isVisible)
                || (!val.card.executorEmail && isVisibleWithoutExecutor))
            && (!searchActive || searchCardsId.includes(val.card.id))
            && (val.card.laneId === id))
            return true;

        for (const value of val.children) {
            const include = isVisible(value[1]);
            if (include)
                return true;
        }
        return false;
    };

    const renderCard = (value, level=-1) => {
        const visible = isVisible(value);

        const card = value.card;
        const isShadow = ((card.laneId !== id)
            || (card.executorEmail && !executors[card.executorEmail].isVisible)
            || (!card.executorEmail && !isVisibleWithoutExecutor)
            || (searchActive && !searchCardsId.includes(card.id)));
        const margin = (level > 0) ? 100 - 100 * Math.pow(0.8, level) : 0;

        const cardToRender = (
            <Card
                key={card.id}
                index={cardIndex++}
                customCardLayout={customCardLayout}
                customCard={children}
                editable={editable}
                isShadow={isShadow}
                isVisible={visible}
                margin={margin}
                hideCardDeleteIcon={hideCardDeleteIcon}
                laneId={id}
                executors={executors}
                isVisibleWithoutExecutor={isVisibleWithoutExecutor}
                {...card}
            />
        );

        let cardObj = cards.find(curCard => curCard.id === card.id);
        if (cardObj) {
            cardObj.indexInComponentsArray = ++indexInComponentsArray;
        } else {
            indexInComponentsArray++;
        }

        return (!isShadow && draggable && cardDraggable) ? (
            <Draggable key={card.id} className="w-100-ni">{cardToRender}</Draggable>
        ) : (
            <div key={card.id}>{cardToRender}</div>
        );
    };

    const renderCategory = (cat, olds) => {
        const cats = update(olds, {$push: [cat]});
        const fullLength = cats.reduce((sum, item) => {
            return sum + item.length;
        }, 0);

        const categoriesList = cats.map((cat, id) => {
            const categorySlice = fullLength > 35 ? cat.slice(0, (35/cats.length)) + '...' : cat;
            return (
                <Category key={id} style={{paddingTop: '10px'}}>{id === 0 ? '' : ' / '}
                    {extractText(clearHtml(categorySlice), 1)}
                </Category>
            );
        });
        indexInComponentsArray++;
        return <CategoryListWrapper key={'category_key' + uuidv1()}>{categoriesList}</CategoryListWrapper>;
    };

    const addChildren = (val, level=1) => {
        if (val.children.size > 0) {
            val.children.forEach(value => {
                listCardsWithCategories.push(renderCard(value, level));
                addChildren(value, level+1);
            });
        }
    };

    const addCards = (value, level=1) => {
        if (value.cards.size > 0) {
            value.cards.forEach(value2 => {
                listCardsWithCategories.push(renderCard(value2));
                addChildren(value2);
            })
        }
    };

    const addCategories = (val, olds=[], level=1, props=this.props) => {
        if (val.categories.size > 0) {
            val.categories.forEach((value, key) => {
                let isVisibleCategory = false;

                for (const el of value.cards) {
                    const cardVisible = isVisible(el[1]);
                    if (cardVisible) {
                        isVisibleCategory = true;
                        break;
                    }
                }

                if (value.cards.size > 0 && isVisibleCategory) {
                    listCardsWithCategories.push(renderCategory(key, olds));
                }
                addCards(value);
                addCategories(value, [...olds, key], level+1);
            })
        }
    };

    const renderCards = (ls=this.props.laneStruct) => {
      addCategories(ls);
      const { t } = this.props;
      if (ls.cards.size > 0) {
          let cardsVisible = false;
          for (const value of ls.cards) {
              const cardVisible = isVisible(value[1]);
              if (cardVisible) {
                  cardsVisible = true;
                  break;
              }
          }

          if (cardsVisible) {
              listCardsWithCategories.push(renderCategory(t`lane.wo_category`, []));
              ls.cards.forEach(value => {
                  listCardsWithCategories.push(renderCard(value));
                  addChildren(value);
              });
          }
      }
    };

    const {addCardMode} = this.state;

    let listCardsWithCategories = [];
    let indexInComponentsArray = -1;
    renderCards();


    return (
      <ScrollableLane
          ref={this.laneDidMount}
          className={'fancy-scroll'}
          isDraggingOver={isDraggingOver}
      >
        <Container
          style={{margin: 0}}
          orientation="vertical"
          groupName="TrelloLane"
          dragHandleSelector=".column-drag-handle"
          dragClass={cardDragClass}
          onDragStart={this.onDragStart}
          onDrop={e => this.onDragEnd(id, e)}
          onDragEnter={() => {}}//this.setState({isDraggingOver: true})}
          onDragLeave={() => {}}//this.setState({isDraggingOver: false})}
          shouldAcceptDrop={this.shouldAcceptDrop}
          getChildPayload={index => this.props.getCardDetails(id, index)}
          nonDragAreaSelector={NON_DRAG_SELECTOR}
        >
          {listCardsWithCategories}
        </Container>
        {editable && !addCardMode && this.renderAddCardLink()}
        {addCardMode && this.renderNewCard()}
      </ScrollableLane>
    )
  };

  renderHeader = () => {
    const {t, customLaneHeader} = this.props;
    if (customLaneHeader) {
      const customLaneElement = React.cloneElement(customLaneHeader, {...this.props});
      return <span>{customLaneElement}</span>;
    } else {
      const {title, icon, titleStyle, spendTime, laborCosts, numberTasks} = this.props;
      return (
        <LaneHeader className="column-drag-handle" onDoubleClick={this.toggleLaneCollapsed}>
          {icon && <div><img className="icon" src={icon} alt={title} /></div>}
          <LaneHeaderTitle style={titleStyle}>
            {title || t`lane.not_set`}
            <LaneHeaderNumberTasks> - <AppPopper trigger="hover" delayShow={200}>
              <span>{numberTasks}</span>
              <SimpleTooltip>{t`lane.task_total`}: {numberTasks}</SimpleTooltip>
            </AppPopper></LaneHeaderNumberTasks>
          </LaneHeaderTitle>
          <LaneHeaderTime>
              {laborCosts > 0 && <TimePopper delayShow={200} spendTime={spendTime} laborCosts={laborCosts}/>}
          </LaneHeaderTime>
        </LaneHeader>
      );
    }
  };

  renderFooter = () => {
    const {collapsibleLanes, cards} = this.props;
    const {collapsed} = this.state;
    if (collapsibleLanes && cards.length > 0) {
      return <LaneFooter onClick={this.toggleLaneCollapsed}>{collapsed ? <ExpandBtn /> : <CollapseBtn />}</LaneFooter>;
    }
  };

  toggleLaneCollapsed = () => {
    this.props.collapsibleLanes && this.setState(state => ({collapsed: !state.collapsed}));
  };

  render() {
    const {loading, isDraggingOver} = this.state;
    // вытащим title, чтобы не попало в attrs.title :/
    const {id, onLaneClick, visible, width, title, ...otherProps} = this.props;

    return (
        <div className="drag-lane">
            {visible &&
            <Resizable
                className="resizer"
                minWidth={350}
                maxWidth={700}
                enable={{top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                defaultSize={{
                    width: width,
                }}
                onResizeStop={(e, dir, ref, delta) => this.onUpdateLaneWidth(title, ref.offsetWidth)}
            >
                <Section {...otherProps} key={id} onClick={() => onLaneClick && onLaneClick(id)} draggable={false}>
                    {this.renderHeader()}
                    {this.renderDragContainer(isDraggingOver)}
                    {loading && <Loader/>}
                    {this.renderFooter()}
                </Section>
            </Resizable>
            }
        </div>
    );
  }
}

Lane.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  title: PropTypes.node,
  index: PropTypes.number,
  laneSortFunction: PropTypes.func,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  customLaneHeader: PropTypes.element,
  customCardLayout: PropTypes.bool,
  cards: PropTypes.array,
  icon: PropTypes.string,
  currentPage: PropTypes.number,
  draggable: PropTypes.bool,
  collapsibleLanes: PropTypes.bool,
  droppable: PropTypes.bool,
  onLaneScroll: PropTypes.func,
  onCardDelete: PropTypes.func,
  onLaneClick: PropTypes.func,
  newCardTemplate: PropTypes.node,
  addCardLink: PropTypes.node,
  editable: PropTypes.bool,
  cardDraggable: PropTypes.bool,
  cardDragClass: PropTypes.string,
  laneStruct: PropTypes.object,
};

Lane.defaultProps = {
  style: {},
  titleStyle: {},
  labelStyle: {},
  label: undefined,
  editable: false,
};

const mapStateToProps = state => ({
   executors: state.boardReducer.executors.byEmail,
   areWithoutExecutorVisible: state.boardReducer.executors.areWithoutExecutorVisible
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({...kanbanActions, ...boardActions, ...laneActions}, dispatch)
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(Lane));
