import React, {Component} from 'react'

import auth from '../auth';
import {withNamespaces} from "react-i18next";

class Login extends Component {

    onSubmit = (e) => {
        const username = this.refs.username.value;
        const pass = this.refs.pass.value;
        auth.login(username, pass);
        e.preventDefault();
    };

    render = () => {
        const { t } = this.props;
        return (
            <div className="login">
                <form onSubmit={this.onSubmit}>
                    <div className="username"><input type="text" ref="username" placeholder="Login" /></div>
                    <div className="password"><input type="password" ref="pass" placeholder="Password" /></div>
                    <div className="error" id="login-error-text">{t`login.error`}</div>
                    <div className="button"><input type="submit" value={t`login.submit`} /></div>
                </form>
            </div>
        );
    };
}

export default withNamespaces()(Login);
