import React, {useState} from "react";
import TooltipTrigger from "react-popper-tooltip";
import PropTypes from "prop-types";


const Popper = (props) => {
    const {className, content, arg} = props;
    const {tooltipRef, getTooltipProps} = arg;

    return <div
        {...getTooltipProps({
            ref: tooltipRef,
        })}
        className={className}
    >
        {content}
    </div>;
};

Popper.propTypes = {
    className: PropTypes.string,
    content: PropTypes.element.isRequired,
    arg: PropTypes.any.isRequired, // TooltipArg
};

const AppPopper = ({children, ...props}) => {
    const [target, content] = children;
    const [visible, setVisible] = useState(false);

    const onVisibilityChange = (tooltipShown) => {
        setVisible(tooltipShown);
    };

    const onMouseDown = () => {
        setVisible(false);
    };

    const {trigger, placement, delayShow, delayHide, ...popperProps} = props;

    return <TooltipTrigger
        trigger={trigger}
        placement={placement}
        delayShow={delayShow}
        tooltipShown={visible}
        onVisibilityChange={onVisibilityChange}
        delayHide={delayHide}
        tooltip={arg => <Popper {...popperProps} arg={arg} content={content}/>}
    >
        {({getTriggerProps, triggerRef}) => {
            if (typeof target === "object" && typeof target.type === "object") {
                return <div
                    onMouseDown={onMouseDown}
                    {...getTriggerProps({ref: triggerRef})}
                >{target}</div>;
            }

            return <target.type onMouseDown={onMouseDown} {...target.props} {...getTriggerProps({ref: triggerRef})}/>;
        }}
    </TooltipTrigger>;
};


AppPopper.propTypes = {
    className: PropTypes.string,
    trigger: PropTypes.any, // TriggerTypes
    delayShow: PropTypes.number,
    delayHide: PropTypes.number,
    placement: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default AppPopper;
