import _ from 'lodash';
import LocalStorageHelper from "./helpers/LocalStorageHelper";
import queryString from "query-string";

const envPrefix = process.env.APP_ENV_PREFIX || '';

const env = (envName, defaultValue) => {
  const envPrefixedName = envPrefix + envName;
  const value = process.env[envPrefixedName];
  if (!value && typeof defaultValue === 'undefined') {
    throw Error(`Setting ${envPrefixedName} must be specified`);
  }
  return value || defaultValue;
};

const kanbanSettingsEnv = env('KANBAN_SETTINGS', {});

export default {
  RF_APP_BASE_URL: env('RF_APP_BASE_URL'),

  RF_API_BASE_URL: env('RF_API_BASE_URL'),

  KANBAN: {
    SPRINT_NODE_TYPE: {
      name: _.get(kanbanSettingsEnv, 'sprint_node_type.name', 'Спринт'),
      property_names: {
        start: _.get(kanbanSettingsEnv, 'sprint_node_type.property_names.start', 'Начало'),
        end: _.get(kanbanSettingsEnv, 'sprint_node_type.property_names.end', 'Окончание')
      }
    },
    CATEGORY_NODE_TYPE: {
      name: _.get(kanbanSettingsEnv, 'category_node_type.name', 'Категория')
    },
    TASK_NODE_TYPE: {
      name: _.get(kanbanSettingsEnv, 'task_node_type.name', 'Задача'),
      property_names: {
        assignee: _.get(kanbanSettingsEnv, 'task_node_type.property_names.assignee', 'Исполнитель'),
        description: _.get(kanbanSettingsEnv, 'task_node_type.property_names.description', 'Постановка'),
        status: _.get(kanbanSettingsEnv, 'task_node_type.property_names.status', 'Статус'),
        labor_costs: _.get(kanbanSettingsEnv, 'task_node_type.property_names.labor_costs', 'Трудозатраты (чел_час)'),
        spend_time: _.get(kanbanSettingsEnv, 'task_node_type.property_names.spend_time', 'Потраченное время')
      },
      values_status_property: {
        ready: _.get(kanbanSettingsEnv, 'task_node_type.values_status_property.ready', 'Готово')
      }
    }
  }
};
