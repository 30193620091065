import React, {Component} from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {BoardDiv} from '../../styles/Base';
import Lane from '../Lane';
import Container from '../../dnd/Container';
import Draggable from '../../dnd/Draggable';
import * as boardActions from '../../actions/BoardActions';
import * as laneActions from '../../actions/LaneActions';
import {NON_DRAG_SELECTOR} from "../../constants";

class BoardContainer extends Component {
  wireEventBus = () => {
    const {actions, eventBusHandle} = this.props;
    let eventBus = {
      publish: event => {
        switch (event.type) {
          case 'ADD_CARD':
            return actions.addCard({laneId: event.laneId, card: event.card});
          case 'REFRESH_BOARD':
            return actions.loadBoard(event.data);
          case 'MOVE_CARD':
            return actions.moveCardAcrossLanes({
              fromLaneId: event.fromLaneId,
              toLaneId: event.toLaneId,
              cardId: event.cardId,
              index: event.index
            });
          case 'UPDATE_LANES':
            return actions.updateLanes(event.lanes);
          default:
              return 0;
        }
      }
    };
    eventBusHandle(eventBus);
  };

  componentWillMount() {
    const {eventBusHandle} = this.props;
    //actions.loadBoard(this.props.lanes)
    if (eventBusHandle) {
      this.wireEventBus();
    }
  };

  componentWillReceiveProps(nextProps) {
    // nextProps.data changes when external Board input props change and nextProps.reducerData changes due to event bus or UI changes
    const {data} = this.props;
    if (nextProps.data && !isEqual(nextProps.data, data)) {
        this.props.actions.loadBoard(nextProps.data);
    }
  };

  getCardDetails = (laneId, index) => {
      return this.props.reducerData.lanes.find(lane => lane.id === laneId).cards.find(card => card.indexInComponentsArray === index)
  };

  onDragStart = ({payload}) => {
      const {handleLaneDragStart} = this.props;
      handleLaneDragStart(payload.id);
  };

  onLaneDrop = ({removedIndex, addedIndex, payload}) => {
    const {actions, handleLaneDragEnd} = this.props;
    actions.moveLane({oldIndex: removedIndex, newIndex: addedIndex});
    actions.saveLanes();
    handleLaneDragEnd(payload.id, addedIndex);
  };

  getLaneDetails = index => {
    return this.props.reducerData.lanes[index];
  };

  _buildBoardStruct(lanes) {
    const categoriesByLane = lanes.reduce((obj, lane) => {
      obj[lane.id] = {
        cards: new Map(),
        categories: new Map()
      };
      return obj
    }, {});

    const cardsById = {};
    for (const lane of lanes) {
      lane.cards.forEach(card => {
        if (!cardsById[card.id]) {
          cardsById[card.id] = {card, children: []};
        } else {
          cardsById[card.id].card = card;
        }

        if (card.parentCardId) {
          if (!cardsById[card.parentCardId]) {
            cardsById[card.parentCardId] = {card: null, children: []};
          }
          cardsById[card.parentCardId].children.push(card);
        }
      });
    }

    for (const lane of lanes) {
      lane.cards.forEach(card => {
        let ctgObj = categoriesByLane[lane.id];

        for (const ctg of card.categories) {
          if (!ctgObj.categories.has(ctg)) {
            ctgObj.categories.set(ctg, {
              cards: new Map(),
              categories: new Map()
            });
          }
          ctgObj = ctgObj.categories.get(ctg);
        }

        let cardMap = ctgObj.cards;

        const chain = [card];
        while (card.parentCardId) {
          card = cardsById[card.parentCardId].card;
          chain.push(card);
        }
        chain.reverse();

        for (const card of chain) {
          if (!cardMap.has(card.id)) {
            cardMap.set(card.id, {
              card: card,
              children: new Map()
            })
          }
          cardMap = cardMap.get(card.id).children;
        }
      })
    }

    return categoriesByLane;
  }

  render() {
    const {id, reducerData, draggable, laneDraggable, laneDragClass, style, ...otherProps} = this.props;

    // Stick to whitelisting attributes to segregate board and lane props
    const passthroughProps = pick(this.props, [
      'onLaneScroll',
      'onLaneClick',
      'addCardLink',
      'laneSortFunction',
      'draggable',
      'cardDraggable',
      'collapsibleLanes',
      'editable',
      'hideCardDeleteIcon',
      'customCardLayout',
      'newCardTemplate',
      'customLaneHeader',
      'handleDragStart',
      'handleDragEnd',
      'cardDragClass',
      'children',
    ]);

    const boardStruct = this._buildBoardStruct(reducerData.lanes);
    return (
      <BoardDiv style={style} {...otherProps} draggable={false}>
        <Container
          orientation="horizontal"
          dragHandleSelector=".column-drag-handle"
          onDragStart={this.onDragStart}
          dragClass={laneDragClass}
          onDrop={this.onLaneDrop}
          lockAxis={'x'}
          getChildPayload={index => this.getLaneDetails(index)}
          groupName={`TrelloBoard${id}`}
          nonDragAreaSelector={NON_DRAG_SELECTOR}
        >
          {reducerData.lanes.map((lane, index) => {
            const {id, droppable, ...otherProps} = lane;
            const laneToRender = (
              <Lane
                key={id}
                id={id}
                getCardDetails={this.getCardDetails}
                index={index}
                droppable={droppable === undefined ? true : droppable}
                laneStruct={boardStruct[id]}
                executors={this.props.reducerData.executors.byEmail}
                isVisibleWithoutExecutor={this.props.reducerData.executors.areWithoutExecutorVisible}
                searchActive={this.props.reducerData.search.active}
                searchCardsId={this.props.reducerData.search.cardsId}
                {...otherProps}
                {...passthroughProps}
              />
            );
            return draggable && laneDraggable ? (
              <Draggable key={lane.id}>{laneToRender}</Draggable>
            ) : (
              <span key={lane.id}>{laneToRender}</span>
            );
          })}
        </Container>
      </BoardDiv>
    );
  };
}

BoardContainer.propTypes = {
  id: PropTypes.string,
  actions: PropTypes.object,
  reducerData: PropTypes.object,
  eventBusHandle: PropTypes.func,
  onLaneScroll: PropTypes.func,
  addCardLink: PropTypes.node,
  onLaneClick: PropTypes.func,
  laneSortFunction: PropTypes.func,
  draggable: PropTypes.bool,
  collapsibleLanes: PropTypes.bool,
  editable: PropTypes.bool,
  hideCardDeleteIcon: PropTypes.bool,
  handleDragStart: PropTypes.func,
  handleDragEnd: PropTypes.func,
  handleLaneDragStart: PropTypes.func,
  handleLaneDragEnd: PropTypes.func,
  customCardLayout: PropTypes.bool,
  newCardTemplate: PropTypes.node,
  customLaneHeader: PropTypes.element,
  style: PropTypes.object,
  laneDraggable: PropTypes.bool,
  cardDraggable: PropTypes.bool,
  cardDragClass: PropTypes.string,
  laneDragClass: PropTypes.string,
};

BoardContainer.defaultProps = {
  handleDragStart: () => {},
  handleDragEnd: () => {},
  handleLaneDragStart: () => {},
  handleLaneDragEnd: () => {},
  editable: false,
  hideCardDeleteIcon: false,
  draggable: false,
  collapsibleLanes: false,
  laneDraggable: true,
  cardDraggable: true,
  cardDragClass: 'react_trello_dragClass',
  laneDragClass: 'react_trello_dragLaneClass'
};

const mapStateToProps = state => {
  return state.boardReducer.lanes ? {reducerData: state.boardReducer} : {};
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({...boardActions, ...laneActions}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardContainer);
