const LocalStorageHelper = {
    getMapId: () => {
        return localStorage.getItem('mapId');
    },

    getNodeId: () => {
        return localStorage.getItem('nodeId');
    },

    setMapId: (mapId) => {
        localStorage.setItem('mapId', mapId);
    },

    setNodeId: (nodeId) => {
        localStorage.setItem('nodeId', nodeId);
    },

    getUserEmail: () => {
        return localStorage.getItem('email');
    },

    getUserPassword: () => {
        return localStorage.getItem('password');
    },

    setUserEmail: (email) => {
        localStorage.setItem('email', email);
    },

    setUserPassword: (password) => {
        localStorage.setItem('password', password);
    },

    deleteUserEmail: () => {
        localStorage.removeItem('email');
    },

    deleteUserPassword: () => {
        localStorage.removeItem('password');
    },

    setLanes: (lanes) => {
        localStorage.setItem('lanes', JSON.stringify(lanes));
    },

    getLanes: () => {
        return JSON.parse(localStorage.getItem('lanes'));
    }
};

export default LocalStorageHelper;
