import { combineReducers } from 'redux';

import boardReducer from './BoardReducer';
import viewsReducer from './ViewsReducer';

const rootReducer = combineReducers({
    boardReducer,
    viewsReducer
});

export default rootReducer;
