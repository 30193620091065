import {SimpleTooltip} from "../../styles/Base";
import AppPopper from "../AppPopper/AppPopper";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {withNamespaces} from "react-i18next";


class TimePopper extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t, delayShow, spendTime, laborCosts} = this.props;

        const fixedSpendTime = +spendTime.toFixed(2);
        const fixedLaborCosts = +laborCosts.toFixed(2);

        return <AppPopper trigger="hover" delayShow={delayShow}>
            <span>{fixedSpendTime} / {fixedLaborCosts} {t`measurement.manhours`}</span>
            <SimpleTooltip>
                {t("measurement.ratio", { spent: fixedSpendTime, costs: fixedLaborCosts })}
            </SimpleTooltip>
        </AppPopper>;
    }
}

TimePopper.propTypes = {
    delayShow: PropTypes.number,
    spendTime: PropTypes.number,
    laborCosts: PropTypes.number,
};

export default withNamespaces()(TimePopper);
