import {createAction} from 'redux-actions';
import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const addCard = createAction('ADD_CARD');
export const removeCard = createAction('REMOVE_CARD');
export const moveCardAcrossLanes = createAction('MOVE_CARD');
export const updateCards = createAction('UPDATE_CARDS');
export const updateLanes = createAction('UPDATE_LANES');
export const paginateLane = createAction('PAGINATE_LANE');
export const moveLane = createAction('MOVE_LANE');
export const toggleVisibleLane = createAction('TOGGLE_VISIBLE_LANE');
export const toggleVisibleLaneAll = createAction('TOGGLE_VISIBLE_LANE_ALL');
export const updateLaneWidth = createAction('UPDATE_LANE_WIDTH');
export const calculateLanesTimeAndNumberTasks = createAction('CALCULATE_LANES_TIME_AND_NUMBER_TASKS');

export const saveLanes = () => (dispatch, getState) => {
    const {order, visibility, width} = getState().boardReducer.taskStatuses;
    LocalStorageHelper.setLanes({order, visibility, width});
};
