import DOMPurify from "dompurify";

const ALLOWED_SANDBOX = [
    "allow-scripts",
    "allow-same-origin",
    "allow-popups",
];

// В unit-тестах на nodejs addHook не существует, потому что нет window
if (DOMPurify.addHook) {
    DOMPurify.addHook("afterSanitizeElements", (node) => {
        if (node instanceof HTMLIFrameElement) {
            let modified = false;

            // добавляем sandbox чтобы включить запреты
            if (node.getAttribute("sandbox") === null) {
                node.sandbox.add(...ALLOWED_SANDBOX);
                modified = true;
            }

            // проверка, что iframe разрешает себе не больше, чем позволено в ALLOWED_SANDBOX
            node.sandbox.forEach((allow) => {
                if (!ALLOWED_SANDBOX.includes(allow)) {
                    node.sandbox.remove(allow);
                    modified = true;
                }
            });

            if (modified) {
                const comment = "This IFrame was restricted by DOM sanitizer";
                node.setAttribute("data-sanitizer-comment", comment);
            }
        }
    });
}

/**
 * Санитизация небезопасного HTML контента
 */
export function clearHtml(html) {
    if (DOMPurify.sanitize === undefined) {
        // eslint-disable-next-line no-console
        console.warn("DOMPurify.sanitize is undefined. This may only happen in tests. Falling back to raw HTML");
        // @ts-ignore
        DOMPurify.sanitize = (text) => text;
    }

    return DOMPurify.sanitize(html, {
        ADD_TAGS: ["iframe"],
        ADD_ATTR: ["sandbox", "scp"],
    });
}
