import React, {Component} from 'react';
import uuidv1 from 'uuid/v1';
import {Link} from 'react-router-dom';

import auth from 'auth';
import backIcon from 'images/back.png';
import logoIcon from 'images/favicon.png';
import logoutIcon from 'images/sign-out-alt-solid.svg';
import settingsIcon from 'images/settings.png';
import {ExecutorsContainer} from "../Executors/ExecutorsContainer";
import {SearchContainer} from "../Search/SearchContainer";
import {Trans, withNamespaces} from "react-i18next";
import {extractText} from "../../utils/extract-text";
import {clearHtml} from "../../utils/clear-html";


class Header extends Component {
    constructor(props) {
        super(props);
        this.id = uuidv1();
    };

    onClickLogout = () => {
        auth.logout();
    };

    render() {
        const {rootInfo, currentView, rootNodeUrl, t} = this.props;
        const rootName = !rootInfo.isFailed ? extractText(clearHtml(rootInfo.name), 1) : rootInfo.errorText;

        return (
            <div className="App-header flex-row">
                <div className="flex-1 root-info">
                    <div className="name-row">
                        <span>{rootName}</span>
                    </div>
                    {
                        (!rootInfo.isFailed && rootInfo.isSprint && ((rootInfo.start && rootInfo.end) ?
                            <div className="period-row">{t("sprint.range.begin", { date: rootInfo.start })} - {t("sprint.range.end", { date: rootInfo.end })}</div>
                            :
                            <div className="period-row">
                                <Trans i18nKey="sprint.range.no_data">
                                    <span/>
                                </Trans>
                            </div>))
                    }
                </div>
                <div>
                    {!rootInfo.isFailed && ((currentView === 'Home') ?
                        <div className="settings-row">
                            <ExecutorsContainer/>
                            <SearchContainer/>
                            <a href={rootNodeUrl} target="_blank" rel="noopener noreferrer" title={t`buttons.to_node`}>
                                <img className="header-icon" src={logoIcon} alt={t`buttons.to_node`}/>
                            </a>
                            <Link to={`/settings${window.location.search}`} title={t`buttons.settings`}>
                                <img className="header-icon" src={settingsIcon} alt={t`buttons.settings`}/>
                            </Link>
                            <span>
                                <img className="header-icon" src={logoutIcon} alt={t`buttons.logout`} title={t`buttons.logout`}
                                 onClick={this.onClickLogout}/>
                            </span>
                        </div>
                            :
                        <div>
                            <Link to={`/${window.location.search}`}>
                                <img className="header-icon" src={backIcon} alt={t`buttons.back`} title={t`buttons.back`}/>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}

export default withNamespaces()(Header);

