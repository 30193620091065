import React, {Component} from 'react';
import '../../styles/css/card_details.css';
import settings from '../../settings';
import buildUrl from 'build-url';
import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";
import {clearHtml} from "../../utils/clear-html";

// todo двойной маппинг через /api/node_property_types
const NODE_PROPERTY_TYPES = {
    REAL: 1,
    INTEGER: 2,
    BOOLEAN: 3,
    TEXT: 5,
    HTML: 6,
    DATE: 7,
    TIME: 8,
    DATETIME: 9,
    FILE: 10,
    USER: 11,
    ENUM: 12,
};

const fileBaseUrl = buildUrl(settings.RF_API_BASE_URL, { path: '/files'});


class CardDetailsProperty extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        typeId: PropTypes.number.isRequired,
        value: PropTypes.any,
    };

    render() {
        const { t, name, value, typeId } = this.props;

        if (!value) return null;

        let renderValue = null;
        switch (typeId) {
            case NODE_PROPERTY_TYPES.BOOLEAN:
                renderValue = value ? t`properties.boolean.true` : t`properties.boolean.false`;
                break;
            case NODE_PROPERTY_TYPES.TEXT:
                renderValue = <pre className={'card-details-pre-property fancy-scroll fancy-scroll-horizontal'}>{ value }</pre>;
                break;
            case NODE_PROPERTY_TYPES.HTML:
                renderValue = <div dangerouslySetInnerHTML={{ __html: clearHtml(value) }}/>;
                break;
            case NODE_PROPERTY_TYPES.DATE:
                renderValue = t("properties.date", { date: new Date(value)});
                break;
            case NODE_PROPERTY_TYPES.TIME:
                renderValue = t("properties.time", { date: new Date(value)});
                break;
            case NODE_PROPERTY_TYPES.DATETIME:
                renderValue = t("properties.datetime", { date: new Date(value)});
                break;
            case NODE_PROPERTY_TYPES.FILE:
                const files = JSON.parse(value); // todo распарсить один раз при загрузке узлов

                renderValue = <div>
                    { files.map(
                        f => <div key={f.filepath}>
                            - <a target={'_blank'}
                                 rel="noopener noreferrer"
                                 href={buildUrl(fileBaseUrl, { path: f.filepath, queryParams: { filename: f.name } })}>
                                { f.name }
                            </a>
                        </div>
                    )}
                </div>;
                break;

            default: renderValue = value
        }

        return (
            <div className="card-details-property">
                <b>{ name }</b>: { renderValue }
            </div>
        )
    }
}

export default withNamespaces()(CardDetailsProperty);
