import React, {Component} from 'react';

import '../../styles/css/app.css';
import Board from '../Board/Board';
import LoaderSpinner from "../LoaderSpinner/LoaderSpiner";

const handleDragStart = (cardId, laneId) => {
    // console.log('drag started')
    // console.log(`cardId: ${cardId}`)
    // console.log(`laneId: ${laneId}`)
};

const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, card) => {
    // console.log('drag ended')
    // console.log(`cardId: ${cardId}`)
    // console.log(`sourceLaneId: ${sourceLaneId}`)
    // console.log(`targetLaneId: ${targetLaneId}`)
    // console.log(`newPosition: ${position}`)
    // console.log(`cardDetails:`)
    // console.log(card)
};


export default class Home extends Component {

    componentDidMount() {
        this.props.onChangeView('Home');
    }

    setEventBus = eventBus => {
        this.setState({eventBus});
    };

    getTypeObjectByName = (typeName) => {
        //проявляется ошибка: иногда typeIdsData == undefined
        let typeObject = this.typeIdsData.find(type => type['name'] === typeName);
        //console.log(typeObject)
        return typeObject;
    };

    addCard = () => {
        this.state.eventBus.publish({
            type: 'ADD_CARD',
            laneId: 'BLOCKED',
            card: {id: 'Ec2Error', title: 'EC2 Instance Down', label: '30 mins', description: 'Main EC2 instance down'}
        });
    };

    render () {
      const {isFetching} = this.props;

      return (
        <div className="App-intro fancy-scroll fancy-scroll-horizontal">
            {isFetching ?
                <LoaderSpinner/>
                    :
                <Board
                    draggable
                    eventBusHandle={this.setEventBus}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    style={{height: 'inherit'}}
                />
            }
        </div>
      );
    };
}
