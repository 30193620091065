import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';
import {Collapse} from 'react-collapse';
import linkIcon from 'images/link-symbol.svg';

import '../styles/css/base.css';
import '../styles/css/app.css';

import {
    CardHeader,
    CardTitle,
    Detail,
    LaneFooter,
    MovableCardWrapper,
    Executor,
    CardTime,
    CardTitleInfo,
    NodeLink,
    NodeLinkImg,
    NodeCommentCountWrapper,
    NodeTotalCommentsCount,
    NodeUnreadCommentsCount, CardTooltip
} from '../styles/Base';
import {CollapseBtn, ExpandBtn} from '../styles/Elements';
import {CARD_TOOLTIP} from '../constants';
import CardDetailsContainer from './CardDetails/CardDetailsContainer';
import AppPopper from "./AppPopper/AppPopper";
import TimePopper from "./widgets/TimePopper";
import {withNamespaces} from "react-i18next";
import {clearHtml} from "../utils/clear-html";


class Card extends Component {
    state = {
        showCollapseButton: false,
        isOpened: false
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    };

    componentDidMount() {
        let clientHeight = this.myRef.current !== null ? (
            this.myRef.current.content.clientHeight
        ) : 0;
        if (clientHeight > 51) {
            this.setState({showCollapseButton: true});
        }
    };

    renderBody = () => {
        if (this.props.customCardLayout) {
            const {customCard, ...otherProps} = this.props;
            return React.cloneElement(customCard, {...otherProps});
        } else {
            const {t, title, description, spendTime, laborCosts, executorEmail, nodeUrl, comments} = this.props;

            return (
                <Fragment>
                  <CardHeader onDoubleClick={this.toggleCardCollapsed}>
                    <CardTitle>
                      <div
                        className="card-title text-overflow"
                        dangerouslySetInnerHTML={{ __html: clearHtml(title) }}
                      />
                      <CardTitleInfo>
                          {spendTime + laborCosts > 0 && <CardTime>
                              <TimePopper delayShow={500} spendTime={spendTime} laborCosts={laborCosts}/>
                          </CardTime>}
                          <NodeLink href={nodeUrl} target="_blank" rel="noopener noreferrer" title={t`card.to_node`}>
                            <NodeLinkImg src={linkIcon} alt={t`card.to_node`}/>
                          </NodeLink>
                          {
                              (comments.unread || comments.total)
                                  ? <NodeCommentCountWrapper>
                                      <NodeUnreadCommentsCount title={t`card.comments.unread`}
                                                               counter={comments.unread}>
                                          {comments.unread}
                                      </NodeUnreadCommentsCount>
                                      <NodeTotalCommentsCount title={t`card.comments.total`}
                                                              counter={comments.total}>
                                          {comments.total}
                                      </NodeTotalCommentsCount>
                                  </NodeCommentCountWrapper>
                                  : null
                          }
                      </CardTitleInfo>
                    </CardTitle>
                    {executorEmail && <Executor>{t`card.assignee`}: {executorEmail}</Executor>}
                  </CardHeader>
                    {description && <div className="text-overflow">
                        <Collapse ref={this.myRef} isOpened={this.state.isOpened} style={{minHeight: 35}}>
                            <Linkify options={{
                                nl2br: true,
                                target: {
                                    url: '_blank'
                                }
                            }}>
                                <Detail className="card-details-property">
                                    <div dangerouslySetInnerHTML={{ __html: clearHtml(description) }}/>
                                </Detail>
                            </Linkify>
                        </Collapse>
                        {this.state.showCollapseButton &&
                        <LaneFooter onClick={this.toggleCardCollapsed}>
                            {this.state.isOpened ? <CollapseBtn/> : <ExpandBtn/>}
                        </LaneFooter>}
                    </div>}
                </Fragment>
            );
        }
    };

    toggleCardCollapsed = () => {
        this.setState(state => ({isOpened: !state.isOpened}));
    };

    render() {
        const {
            t,
            id,
            editable,
            isShadow,
            isVisible,
            margin,
            hideCardDeleteIcon,
            dragStyle,
            executors,
            isVisibleWithoutExecutor,
            title, // вытащим, чтобы не попало в attrs.title :/
            color,
            ...otherProps
        } = this.props;


        return (
            <div className="card-root">
                {isVisible &&
                <AppPopper trigger="hover" delayShow={500} delayHide={100} className={CARD_TOOLTIP}>
                    <MovableCardWrapper
                        key={id}
                        data-id={id}
                        className={`column-drag-handle ${otherProps.isNodeLink && 'node-link'}`}
                        style={{
                            ...dragStyle,
                        }}
                        color={color}
                        isShadow={isShadow}
                        margin={margin}
                        {...otherProps}
                        data-tip
                        data-for={this.props.id}
                    >
                        {this.renderBody()}
                    </MovableCardWrapper>
                    <CardTooltip><CardDetailsContainer nodeBody={this.props.nodeBody}/></CardTooltip>
                </AppPopper>}
            </div>
        );
    };
}

Card.defaultProps = {
    customCardLayout: false,
    editable: false,
    dragStyle: {}
};

Card.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    label: PropTypes.string,
    laneId: PropTypes.string.isRequired,
    removeCard: PropTypes.func,
    onClick: PropTypes.func,
    metadata: PropTypes.object,
    dragStyle: PropTypes.object,
    customCardLayout: PropTypes.bool,
    customCard: PropTypes.node,
    isShadow: PropTypes.bool,
    isVisible: PropTypes.bool,
    margin: PropTypes.number,
    editable: PropTypes.bool,
    executor: PropTypes.string,
    color: PropTypes.string
};

export default withNamespaces()(Card);
