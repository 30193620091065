import React, {Component} from "react";
import {SearchIcon, SearchInput, SearchStyle} from '../../styles/Base'
import searchIcon from 'images/search.png';
import cancelIcon from 'images/cancel.png';

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            doSearch: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange(event) {
        const searchQuery = event.target.value.toString();
        this.setState({searchQuery: searchQuery});

        if (this.state.doSearch !== null)
            clearTimeout(this.state.doSearch);

        this.setState({doSearch: setTimeout(() => {
            if (searchQuery.length > 3) {
                if (!this.props.active)
                    this.props.onToggleSearchMode();
                this.props.onToggleSearchQueryMode(searchQuery.toLowerCase());
            }
            else if (this.props.active) {
                this.props.onToggleSearchMode();
            }
        }, 500)});
    }

    handleClick() {
        this.setState({searchQuery: ""});
        if (this.state.doSearch !== null)
            clearTimeout(this.state.doSearch);
        this.props.onToggleSearchMode()
    }

    render() {
        return <SearchStyle>
            <SearchInput type="text" value={this.state.searchQuery} onChange={this.handleChange}/>
            {this.props.active
                ? <SearchIcon src={cancelIcon} onClick={this.handleClick}/>
                : <SearchIcon src={searchIcon}/>}
        </SearchStyle>;
    }
}
